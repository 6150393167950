<template>
  <div class="movie-hall page">
    <!-- <van-nav-bar
        class="nav-bar"
        :title="$t('视频影院')"
    /> -->
    <!-- <van-tabs v-model="active" animated swipeable  @change="OnChange">
      <van-tab v-for="(v,key) in videolitem" :key="key" :title="v.name" :name="v.key" ></van-tab>
    </van-tabs> -->
   
   <section id="team" class="team mowpad relative">
   	<!-- <div class="bg"></div> -->
   	<div class="title">
   		<h1>TRAENCO</h1></div>
   	<div class="mowtainer">
   		<div class="mowgrid">
   			<div class="member">
   				<div class="image"><img src="/img/bs/b1.jpg" alt=""></div>
   				<!--p>Chairman</p>
   				<h2>Tae-won Chey</h2-->
				</div>
   			<div class="member">
   				<div class="image"><img src="/img/bs/b2.jpg" alt=""></div>
   				<!--p>CEO and President</p>
   				<h2>Jeon Kwang-hyun</h2-->
				</div>
   			<div class="member">
   				<div class="image"><img src="/img/bs/b3.jpg" alt=""></div>
   				<!--p>CEO, SK pharmteco</p>
   				<h2>Joerg Ahlgrimm</h2-->
   			</div>
   			<div class="member">
   				<div class="image"><img src="/img/bs/b4.jpg" alt=""></div>
   				<!--p>President, SK biotek Ireland CEO</p>
   				<h2>Joyce Fitzharris</h2-->
				
			</div>
			<div class="member">
				<div class="image"><img src="/img/bs/b5.jpg" alt=""></div>
				<!--p>President, SK biotek Ireland CEO</p>
				<h2>Joyce Fitzharris</h2-->
				
			</div>
			<div class="member">
				<div class="image"><img src="/img/bs/b6.jpg" alt=""></div>
				<!--p>President, SK biotek Ireland CEO</p>
				<h2>Joyce Fitzharris</h2-->
				
			</div>
			<div class="member">
				<div class="image"><img src="/img/bs/b7.jpg" alt=""></div>
				<!--p>President, SK biotek Ireland CEO</p>
				<h2>Joyce Fitzharris</h2-->
				
			</div>
			<div class="member">
				<div class="image"><img src="/img/bs/b8.jpg" alt=""></div>
				<!--p>President, SK biotek Ireland CEO</p>
				<h2>Joyce Fitzharris</h2-->
				
			</div>
   			<!--div class="member">
   				<div class="image"><img src="/img/bs/b5.jpg" alt=""></div>
   				<p>CEO</p>
   				<h2>Woncheol Park</h2></div>
   			<div class="member">
   				<div class="image"><img src="/img/bs/b6.png" alt=""></div>
   				<p>CEO</p>
   				<h2>Kyung-il Park</h2></div>
   			<div class="member">
   				<div class="image"><img src="/img/bs/b7.png" alt=""></div>
   				<p>CEO</p>
   				<h2>Jang Yong Ho</h2></div>
   			<div class="member">
   				<div class="image"><img src="/img/bs/b8.png" alt=""></div>
   				<p>CEO, Yposkesi</p>
   				<h2>Alain Lamproye</h2></div>
   			<div class="member">
   				<div class="image"><img src="/img/bs/b9.png" alt=""></div>
   				<p>CEO</p>
   				<h2>Trần Thanh Tân</h2></div-->
			
				<!--div class="image"><img src="/img/bs/b6.jpg" style="width:320px;" alt=""></div>
				<br>
			
				<div class="image"><img src="/img/bs/b7.jpg" style="width:320px;" alt=""></div>		
				<br-->
				<div class="image"><img src="/img/bs/a1.jpg" style="width:320px;" alt=""></div>
				<br>
				<div class="image"><img src="/img/bs/a2.jpg" style="width:320px;" alt=""></div>
				<br>
				<div class="image"><img src="/img/bs/a3.jpg" style="width:320px;" alt=""></div>
				<br>
				<!-- <div class="image"><img src="/img/bs/a4.jpg" style="width:320px;" alt=""></div> -->
				
	<br>
	<br>
		</div>	
   	</div>
   </section>
   
  </div>
</template>

<script>
import { Toast } from 'vant';
export default {
  data() {
    return {
      active: 0,
      isLoading: false,
      count:0,
      loading: false,
      finished: false,
      refreshing: false,
      videolitem: [],
      videolist: [],
      number:0,
      page:0,
      videoSwiperOption: {
        slidesPerView: 'auto',
        spaceBetween: 0,
        slidesPerGroup : 1,
      }
    };
  },
  methods: {
    getVideoClass(){
      this.$http({
        method: 'get',
        url: 'video_class'
      }).then(res=>{
        this.videolitem = res.data;
      })
    },
    toPlayVideo(id){
      if(!localStorage.getItem('token')){
        this.$router.push({path:'/Login'})
      }else {
        this.$router.push({path:'/PlayVideo?id='+id})
      }

    },
    itemChange(){
      this.active = this.$refs.swiper.swiper.activeIndex
      this.OnChange()
    },
    getVideoList(){
      this.$http({
        method: 'get',
        data:{id:this.active,page:this.page},
        url: 'video_list'
      }).then(res=>{
        this.videolist = this.videolist.concat(res.data.data);
        this.count = res.data.count;
        this.page++;

      })
    },
    onLoad() {
        this.getVideoList();
      let timer = setTimeout(() => {
        if (this.refreshing) {
          this.videolist = [];
          this.refreshing = false;
        }
        this.loading = false;
        if (this.videolist.length === this.count) {
          this.finished = true;
        }
        this.finished && clearTimeout(timer);//清除计时器
      }, 500);
    },
     OnChange(){
      this.videolist = [];
      this.number = 0;
      this.page = 0;
      this.count = 0;
      this.getVideoList();//获取视频列表

    },
    onRefresh() {
      setTimeout(() => {
        this.finished = false;
        this.loading = true;
        this.onLoad();
        this.isLoading = false;
        Toast('load success');
      }, 500);
    },
  },
  created() {
    this.getVideoClass();//获取视频类目
    this.OnChange()
  }
};
</script>

<style src='../../assets/css/b1show.css' scoped></style>
<style src='../../assets/css/b2show.css' scoped></style>
<style lang='less' scoped>
	/* @import "../../assets/css/b1show.css";
	@import "../../assets/css/b2show.css"; */
.page{
  position: absolute!important;
  top: 0;
  left: 0;
  right: 0;
  background-color: #f2f2f5;
}
.nav-bar{
  background: linear-gradient(90deg,#500cfd,#9e9ce7);
  height: 100px;

}
.van-nav-bar {
  line-height: 50px;
}

::v-deep .van-nav-bar__title {
  max-width: 60%;
  margin: 0 auto;
  color: #ffffff;
  font-size: 35px;
}
::v-deep .van-nav-bar__content {
  height: 100px;
}

.movie-hall{
  display: flex;
  flex-direction: column;
  bottom: 100px;
  background: #f2f2f5;
}
::v-deep .van-tabs__nav {
  background: linear-gradient(90deg,#500cfd,#9e9ce7);
}
::v-deep .van-tab {
  color: #ffffff;
  font-size: 30px;
}
::v-deep .van-tabs__line {
  bottom: 15px;
  width: 55px;
  height: 7px;
  border-radius: 0px;
  background-color: #ffffff;
}
::v-deep .van-tabs--line .van-tabs__wrap {
  height: 100px;
}
::v-deep .van-tabs__wrap--scrollable .van-tab {
  padding: 0 23px;
}
::v-deep  .van-hairline--bottom::after {
  border-bottom-width: 0px;
}
.video_swiper {
  width: 100%;
  flex: 1;
  .swiper-slide {
    flex-shrink: 0;
    flex-grow: 0;
    flex-basis: 100%;
    justify-content: center;
    height: 100%;
    position: relative;
    transition-property: transform;
  }
}
.movie-list-tab {
  overflow: auto;
  height: 100%;
}
::v-deep .van-pull-refresh__track .van-pull-refresh__head *{
  color: #000;
  font-size: 35px;
}
.movie-list-tab .hot-recommend-div{
  height: 100%;
  margin: 10px auto;
  /* display: flex; */
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  //overflow: auto;
}
.list-item{
  display: flex;
  width: calc(100% - 50px);
  margin: 10px auto;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.list-item .movie-list-item:nth-child(odd) {
  margin-right: 20px;
}
.movie-list-item .cover_img{
  border-radius: 20px;
  width:335px;
  height:290px;
}
.movie-list-item{
	margin-top: 2px;
  margin-bottom: -10px;
}
.list-item .movie-list-item-bottom{
  position: relative;
  width: 335px;
  bottom: 42px;
}
.list-item .movie-list-item-bottom .movie-time-div{
  background-color: rgba(0,0,0,.4);
}
.list-item .movie-list-item-bottom>div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.list-item .movie-list-item-bottom .movie-time-div .van-count-down {
  color: #fff;
}
.list-item .movie-list-item .movie-time-div span:first-child {
  overflow: hidden;
  white-space: nowrap;
  /* width: 180px; */
  padding-left: 8px;
  font-size: 25px;
}
.list-item .movie-time-div {
  color: #fff;
  border-radius: 0 0 20px 20px;
  height: 35px;
}
#team {
    font-family: Inter,sans-serif;
    font-weight: 400;
    color: #fff!important;
}
</style>
